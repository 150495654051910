.form-control-sm {
  font-size: 12px !important;
}
.form-control:focus, .form-select:focus {
  box-shadow: none !important;
  border-color: var(--primary) !important;
}
.form-select-sm {
  font-size: 12px !important;
}
.form-check-input:checked {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}
.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(81, 81, 118, 0.25) !important;
}
.question-text-area {
  width: 100%;
  border: 0;
  border-radius: 3px;
  outline: 1px rgb(184, 184, 184) solid;
}
.question-text-area:focus-visible{
  outline: 1px var(--primary) solid;
}
.question-text-holder {
  margin-bottom: 0.5em;
}
.question-container {
  margin-bottom: 3em;
  font-size: 14px;
}
