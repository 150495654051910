.landing-screen {
  padding: 0em;
  height: 100vh;
  overflow-x: hidden;
}
.landing-screen img {
  transform: scale(0.4);
}
.landing-tag {
  margin-bottom: 1rem;
}
.logo-row {
  background-color: var(--lightGrey);
  padding: 1em;
  padding-bottom: 2em;
  margin-bottom: 5em;
}
.logo-row img {
  position: fixed;
}
.middle-form {
  padding: 0 25vw !important;
}
.forms-thumbnail {
  background-color: var(--primary);
  width: 100%;
  height: 200px;
  border-radius: 7px;
  background-size: cover !important;
  background-position: center !important;
}
.main-content {
  width: 100%;
  min-height: 100%;
  background-color: white;
  border-radius: 5px;
  padding: 2em;
  box-shadow: -3px 3px 8px 0px rgb(0 0 0 / 16%);
  font-family: 'HelveticaNeue';
  transform: translate(20px, -20px);
}
.main-content .mc-heading {
  font-family: 'Teko';
  font-size: 4em;
  line-height: 1em;
}
.ques-container {
  margin-top: 1em;
}
.four-oh-four-cont {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.four-oh-four-div {
  border-radius: 10px;
  background-color: white;
  padding: 3em 5em;
  box-shadow: 0px 0px 6px 2px rgb(128 128 128 / 20%);
  text-align: center;
}
.four-oh-four-p {
  text-align: center;
  font-family: 'Teko';
  font-size: 8em;
  font-weight: 900;
  color: var(--primary);
}

@media screen and (max-width: 800px) {
  .middle-form {
    padding: 0 2em !important;
  }
  .main-content {
    transform: none !important;
    margin-top: 1em;
    padding: 1em;
  }
  .main-content .mc-heading {
    font-size: 3em;
  }
  .logo-row img {
    position: initial;
  }
  .logo-row {
    margin-bottom: 0;
  }
  .img-col {
    display: flex;
    justify-content: center;
  }
}